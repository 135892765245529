import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby'

import HalfContent from '$components/common/HalfContent'
import translator from '$utils/translator'

const NotFound = ({ data: { over } }) => {
  const { notFound } = translator()

  return (
    <BackgroundImage
      critical
      className="contentBackgroundImage"
      fluid={over.frontmatter.background.childImageSharp.fluid}
      style={{ height: '100%' }}
    >
      <HalfContent
        side="left"
        text={<p>{notFound.body}</p>}
        title={notFound.title}
      />
    </BackgroundImage>
  )
}

export default NotFound

export const query = graphql`
  query NotFound($locale: String!) {
    over: markdownRemark(
      frontmatter: { title: { eq: "Over WillemUitvaart" } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        background {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
